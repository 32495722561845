<template>
  <!-- <CBox bg="bgMain" height="full">
    <CBox ml="60px" mr="60px" pt="5px" pl="10px" pr="10px" pb="5px"> -->
      <!-- <CHeading color="fontMain" size="2xl">Dashboard Premium</CHeading>
      <CDivider borderColor="dividerColor" /> -->
  <CBox>
      <!-- <HeaderPremium />
      <Breadcrumb />
      <CStack
        display="flex"
        direction="row"
        :spacing="6"
        mt="20px"
        mb="50px"
        >
        <SidebarDashboard :currentUser="currentUser" /> -->
        <Loading :active="isLoading" :is-full-page="true" />
        <DetailInteraksi :isOpen="isOpenDetail" @close="isOpenDetail = false" :detail="detailData" />
        <CBox w="100%" bg="white" border="1px" borderColor="borderBox">
          <CBox d="flex" mx="32px" my="20px" justifyContent="space-between">
            <CText fontWeight="bolder" fontSize="20px" color="fontMain">Interaksi Pengunjung</CText>
          </CBox>
          <CDivider borderColor="borderBox" border="1.5px" />
          <CBox mx="32px" my="20px">
            <table style="width: 100%;" class="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>
                    <CBox d="flex" justifyContent="space-between">
                      <CBox></CBox>
                      <CBox>Nama Lengkap</CBox>
                      <CBox d="flex" class="pointer">
                        <CImage :src="require(`@/assets/icon/arrow-up.svg`)" />
                        <CImage :src="require(`@/assets/icon/arrow-down.svg`)" />
                      </CBox>
                    </CBox>
                  </th>
                  <th>Email</th>
                  <th>
                    <CBox d="flex" justifyContent="space-between">
                      <CBox></CBox>
                      <CBox>Waktu</CBox>
                      <CBox d="flex" class="pointer">
                        <!-- <CImage :src="require(`@/assets/icon/arrow-up.svg`)" /> -->
                        <CImage :src="require(`@/assets/icon/arrow-down.svg`)" />
                      </CBox>
                    </CBox>
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in visitorMessages.data" :key="index">
                  <td>{{ item.orderNumber }}</td>
                  <td style="text-align: left;">{{ item.name }}</td>
                  <td style="text-align: left;">{{ item.email }}</td>
                  <td>{{ item.created_at_str }}</td>
                  <td><ButtonBlue @click.native="openDetail(item)" label="Baca" size="sm" /></td>
                </tr>
              </tbody>
            </table>
            <CBox w="100%" my="50px">
              <CBox d="flex" w="inherit" alignItems="center" justifyContent="center">
                <CBox d="flex" :class="{ 'pointer': page != 1 }" @click="handlePrev()">
                  <CImage :src="require(`@/assets/icon/chevron-left.svg`)" mx="5px" />
                  <CText mx="5px" color="borderBox">Prev</CText>
                </CBox>
                <CBox v-for="(item, index) in visitorMessages.meta.page_list" :key="index" w="19px" align="center" mx="5px" :bg="item == page ? 'activeMenuPagination' : ''" :class="{ 'pointer': item != page }" @click="handlePage(item)">
                  <CText :color="item == page ? 'activeMenu' : 'borderBox'">{{ item }}</CText>
                </CBox>
                <CBox d="flex" :class="{ 'pointer': page != visitorMessages.meta.total_page }" @click="handleNext()">
                  <CText mx="5px" color="borderBox">Next</CText>
                  <CImage :src="require(`@/assets/icon/chevron-right.svg`)" mx="5px" />
                </CBox>
              </CBox>
            </CBox>
          </CBox>
        </CBox>
      <!-- </CStack> -->
    <!-- </CBox> -->
  </CBox>
</template>

<script>
// import { GET_USER } from "@/store/auth.module"
import { GET_VISITOR_MESSAGES } from "@/store/visitor-message.module"
// import SidebarDashboard from "@/components/sidebar/SidebarDashboard.vue"
// import Breadcrumb from "@/components/breadcrumb/Breadcrumb.vue"
// import HeaderPremium from "@/components/header/HeaderPremium.vue"
import ButtonBlue from "@/components/button/ButtonBlue.vue"
// import ButtonSecondary from "@/components/button/ButtonSecondary.vue"
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import DetailInteraksi from "@/components/modal/DetailInteraksi.vue";

export default {
  metaInfo: {
    title: "Pesan Pengunjung",
    titleTemplate: "%s - Gethired.id"
  },
  components: {
    // SidebarDashboard,
    // Breadcrumb,
    // HeaderPremium,
    ButtonBlue,
    // ButtonSecondary
    Loading,
    DetailInteraksi
  },
  data() {
    return {
      visitorMessages: {},
      page: 1,
      isLoading: false,
      currentUser: {},
      isOpenDetail: false,
      detailData: {}
    }
  },
  mounted() {
    this.getData()
  },
  // computed: {
  //   currentUser() {
  //     const user = this.$store.getters.currentUser;
  //     return user;
  //   },
  // },
  methods: {
    async getData() {
      this.isLoading = true;
      const params = { page: this.page };
      await Promise.all([
        // this.$store.dispatch(GET_USER),
        this.$store.dispatch(GET_VISITOR_MESSAGES, params),
      ]);
      this.visitorMessages = this.$store.getters.currentVisitorMessages;
      // this.currentUser = this.$store.getters.currentUser;
      this.isLoading = false;
    },
    openDetail(item) {
      this.detailData = item;
      this.isOpenDetail = true;
    },
    handlePage(page) {
      if (page == "...") return;
      if (this.page == page) return;
      this.page = page
      this.getData()
    },
    handlePrev() {
      if (this.page == 1) return;
      this.page = this.page - 1
      this.getData()
    },
    handleNext() {
      if (this.page == this.visitorMessages.meta.total_page) return;
      this.page = this.page + 1
      this.getData()
    },
  }
}
</script>

<style scoped>
.table th, .table td {
  /* font-weight: $table-head-font-weight;
  font-size: $table-head-font-size; */
  border-width: 1px;
  border-color: #c7c7c7;
  color: #888888;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}
.table th {
  background-color: #f4f4f4;
}
.pointer {
  cursor: pointer;
}
</style>
