<template>
  <CBox>
    <CModal
      :is-open="isOpen"
      :on-close="onClose"
    >
      <CModalContent ref="content" maxW="933px">
        <CBox bg="white" minH="400px">
          <CBox d="flex" mx="50px" mt="20px" mb="30px">
            <CBox>
              <CText fontWeight="bolder" color="fontMain">Pengirim</CText>
              <CBox d="flex">
                <CText color="fontInformation">{{ detail.name }} ({{ detail.email }})</CText>
                <CBox d="flex" bg="bgSocialBadge" borders="1px" borderRadius="25px" w="120px" h="25px" ml="10px" mr="10px" class="pointer" @click="setClipboard(detail.email)">
                  <CBox mx="auto" h="inherit">
                    <CStack d="table-cell" direction="row" h="inherit" verticalAlign="middle">
                      <CBox d="table-cell" verticalAlign="middle" h="inherit">
                        <CImage :src="require('@/assets/icon/copy-gray.svg')" w="15px" h="15px" />
                      </CBox>
                      <CBox d="table-cell" verticalAlign="middle" h="inherit" pl="10px">
                        <CText color="fontCopyEmail" fontSize="12px">Copy Email</CText>
                      </CBox>
                    </CStack>
                  </CBox>
                </CBox>
              </CBox>
            </CBox>
            <CModalCloseButton bg="white" />
          </CBox>
          <CDivider borderColor="borderBox" border="1.5px" />
          <CBox mx="50px" my="20px">
            <CText color="fontInformation">{{ detail.created_at_str }}</CText>
            <CText color="fontMain" mt="10px">
              {{ detail.message }}
            </CText>
          </CBox>
        </CBox>
      </CModalContent>
      <CModalOverlay />
    </CModal>
  </CBox>
</template>

<script>

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    setClipboard(value) {
      var tempInput = document.createElement("input");
      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value = value;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      this.$toast({
        title: 'Copied',
        status: 'success',
        duration: 3000,
        position: 'top',
        isClosable: false
      })
    },
  }
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>

